import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Numbered from "../components/Headings/Numbered"
import Heading from "../components/Headings/Heading"
import SubHeading from "../components/Headings/SubHeading"
import Sidebar from "../components/Sidebar/Sidebar"
import {
  FaArrowLeft,
  FaBriefcaseMedical,
  FaClinicMedical,
  FaUserNurse,
} from "react-icons/fa"
import styled from "styled-components"
import Accordion from "../components/Sidebar/Accordion"
import parseCategoryData from "../utils/parseCategoryData"
import Title from "../components/Headings/Title"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`

const AIPage = ({ data }) => {
  const subjectCategories = parseCategoryData(data.allMdx.edges)
  const [ToggleSidebar, setToggleSidebar] = useState(true)
  const toggle = () => setToggleSidebar(!ToggleSidebar)

  return (
    <Layout>
      <Seo title="Artificial Intelligence" />
      <section className="ai-section">
        <Title>Artificial Intelligence</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            {ToggleSidebar && (
              <div className="col-lg-3 mb-4" id="sticky-sidebar">
                <div
                  className="card shadow border-primary sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Chapters</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Accordion allowMultipleOpen>
                        {Object.keys(subjectCategories).map((category, i) => {
                          return (
                            <div key={i} label={category} isOpen>
                              <Sidebar
                                subjectLink={`artificial_intelligence`}
                                category={subjectCategories[category]}
                                target="ToggleSidebar"
                              />
                            </div>
                          )
                        })}
                      </Accordion>
                    </InnerScroll>
                  </Wrap>
                </div>
              </div>
            )}

            <div className={ToggleSidebar ? "col-lg-9 mb-4" : "col-lg-12 mb-4"}>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-header">
                  <button
                    onClick={toggle}
                    id="ToggleSidebar"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <FaArrowLeft /> Toggle Sidebar
                  </button>
                </div>

                <div className="card-body">
                  <Heading>What is Artificial Intelligence ?</Heading>

                  <p>
                    {" "}
                    Artificial intelligence (AI) is the simulation of human
                    intelligence processes by machines, especially computer
                    systems. Specific applications of AI include expert systems,
                    natural language processing (NLP), speech recognition and
                    machine vision.
                    <br />
                    <br />
                    “The effects of AI will be magnified in the coming decade,
                    as manufacturing, retailing, transportation, finance,
                    healthcare, law, advertising, insurance, entertainment,
                    education, and virtually every other industry transform
                    their core processes and business models to take advantage
                    of machine learning.” <br />
                    <br />
                    AI programming focuses on three cognitive skills: learning,
                    reasoning and self-correction.
                    <br />
                    <br />
                    <SubHeading>Learning processes</SubHeading> This aspect of
                    AI programming focuses on acquiring data and creating rules
                    for how to turn the data into actionable information. The
                    rules, which are called algorithms, provide computing
                    devices with step-by-step instructions for how to complete a
                    specific task.
                    <br />
                    <br />
                    <SubHeading>Reasoning processes</SubHeading> This aspect of
                    AI programming focuses on choosing the right algorithm to
                    reach a desired outcome.
                    <br />
                    <br />
                    <SubHeading>Self-correction processes</SubHeading> This
                    aspect of AI programming is designed to continually
                    fine-tune algorithms and ensure they provide the most
                    accurate results possible.
                    <br />
                  </p>

                  <Heading>What is Machine Learning ?</Heading>
                  <p>
                    Machine learning (ML) is an application of AI that provides
                    systems the ability to automatically learn and improve from
                    experience without being explicitly programmed. Machine
                    learning focuses on the development of computer programs
                    that can access data and use it learn for themselves.
                    <br />
                    <br />
                    The process of learning begins with observations or data,
                    such as examples, direct experience, or instruction, in
                    order to look for patterns in data and make better decisions
                    in the future based on the examples that we provide. The
                    primary aim is to allow the computers learn automatically
                    without human intervention or assistance and adjust actions
                    accordingly.
                    <br />
                    <br />
                    Although a machine learning model may apply a mix of
                    different techniques, the methods for learning can typically
                    be categorized as three general types:
                    <br />
                    <br />
                    <SubHeading>Supervised learning</SubHeading> The learning
                    algorithm is given labeled data and the desired output. For
                    example, pictures of dogs labeled “dog” will help the
                    algorithm identify the rules to classify pictures of dogs.
                    <br />
                    <br />
                    <SubHeading>Unsupervised learning</SubHeading> The data
                    given to the learning algorithm is unlabeled, and the
                    algorithm is asked to identify patterns in the input data.
                    For example, the recommendation system of an e-commerce
                    website where the learning algorithm discovers similar items
                    often bought together.
                    <br />
                    <br />
                    <SubHeading>Reinforcement learning</SubHeading> The
                    algorithm interacts with a dynamic environment that provides
                    feedback in terms of rewards and punishments. For example,
                    self-driving cars being rewarded to stay on the road.
                    <br />
                  </p>

                  <Heading>Why you should learn AI and ML ?</Heading>

                  <div className="row">
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaBriefcaseMedical />
                        </div>
                        <h4>Bright Career</h4>
                        <p>
                          AI is at its growth stage right now. Pursuing a career
                          in AI at this time not only assures better than a
                          decent salary, but also promising opportunities which
                          will help you grow.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaClinicMedical />
                        </div>
                        <h4>Skill of the Century</h4>
                        <p>
                          AI will replace a lot of jobs that humans do, as well
                          as it will create many and different job opportunities
                          in related fields. In order to be in the loop,
                          everyone must have at least some information regarding
                          AI.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaUserNurse />
                        </div>
                        <h4>AI is Versatile</h4>
                        <p>
                          AI is not just limited to the computer and
                          space-related industries, but it also plays a
                          significant role in industries like automobile,
                          healthcare, security, mobile, fraud detection,
                          clothing, banking and finance sector and much more.
                        </p>
                      </div>
                    </div>
                  </div>

                  <Heading>Who should learn AI and ML ?</Heading>
                  <p>
                    <SubHeading>Software Developers</SubHeading> Software
                    developers can learn AI and ML to create intelligent
                    applications and algorithms that can learn and adapt based
                    on user interactions.
                    <br />
                    <br />
                    <SubHeading>Data Scientists</SubHeading> Data scientists can
                    learn AI and ML to analyze large datasets, identify
                    patterns, and make predictions that can inform business
                    decisions.
                    <br />
                    <br />
                    <SubHeading>Business Professionals</SubHeading> Business
                    professionals can learn AI and ML to understand how these
                    technologies can be used to automate processes, improve
                    customer experiences, and increase revenue.
                    <br />
                    <br />
                    <SubHeading>Researchers</SubHeading> Researchers can learn
                    AI and ML to develop new algorithms and models that can
                    advance the state of the art in their respective fields.
                    <br />
                    <br />
                    <SubHeading>Students</SubHeading> Students interested in
                    technology and its impact on society can learn AI and ML to
                    gain a deeper understanding of these fields and how they are
                    transforming various industries.
                    <br />
                    <br />
                    <SubHeading>AI for Everyone</SubHeading> anyone interested
                    in technology, data analysis, automation, or innovation can
                    benefit from learning AI and ML. These fields are rapidly
                    growing and are becoming increasingly important in various
                    industries, making them an essential area of study for
                    anyone interested in the future of technology.
                    <br />
                  </p>
                  <Heading>Learning Objectives</Heading>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>1</Numbered>
                        <h5 className="m-2">
                          Understand the Fundamentals: To learn AI, one should
                          first understand the basic concepts and fundamentals
                          of the field, such as machine learning, natural
                          language processing, robotics, and computer vision.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>2</Numbered>
                        <h5 className="m-2">
                          Learn Programming Skills: To build AI applications and
                          algorithms, one should have programming skills in
                          languages like Python, Java, or C++. It is important
                          to learn how to write algorithms and use libraries for
                          AI applications.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>3</Numbered>
                        <h5 className="m-2">
                          Gain Knowledge in Math and Statistics: To understand
                          the mathematical models and algorithms behind AI, one
                          should have knowledge of probability, linear algebra,
                          calculus, and statistics.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>4</Numbered>
                        <h5 className="m-2">
                          Learn Data Preparation and Management: AI algorithms
                          require large amounts of data to learn and make
                          predictions. Therefore, one should learn how to
                          collect, clean, and preprocess data to feed into the
                          algorithms.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>5</Numbered>
                        <h5 className="m-2">
                          Build and Train AI Models: To create intelligent
                          systems, one should learn how to build and train AI
                          models using machine learning algorithms. It is
                          essential to learn how to evaluate the model's
                          performance and fine-tune it to improve its accuracy.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>6</Numbered>
                        <h5 className="m-2">
                          Understand Ethical and Social Implications: AI has the
                          potential to impact society in significant ways.
                          Therefore, it is crucial to understand the ethical and
                          social implications of AI, including bias, privacy,
                          and security concerns.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AIPage

export const pageQuery = graphql`
  query AIPage {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(artificial_intelligence)/.*.(mdx)/" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            topLevelDir
            subDir
          }
        }
      }
    }
  }
`
